import Input from '../../common/Input';
import { useFormsignup } from '../../common/utils/useFormsignup';
import { Container, StyledButton, FormGroup, Span, Large} from "../../common/ContentBlockCss/styles"
import { Styles } from "../../styles/styles";
import InputPassword from '../../common/PasswordInput';
import {GoogleLoginButton, FacebookLoginButton} from 'react-social-login-buttons';
import GoogleLogin from "../../common/GoogleLogin";
import  {LoadingSpinner}  from "../../common/LoadingSpinner"
import { useState } from 'react';
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdparty";

function LoginPage() {
    const [emailvalid, setEmailvalid] = useState(false);
    const [passvalid, setPassvalid]   = useState(false);
    const [loading, setLoading]   = useState(false);
   
    const { values, errors, handleChange, handleSubmit } = useFormsignup();
    values.name = "login";


   
    const  GSubmit = async () =>{
   
      setLoading(true);
      await GoogleLogin();
      setLoading(false);
  
     }
    const ValidationEmail = () => {
      let errorMessage:string = ""; 
     if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
      errorMessage = "Email address is invalid";
      setEmailvalid(false)
      }
      else {setEmailvalid(true)}
      return <Span>{errorMessage}</Span>;
      };

      const ValidationPassword = () => {
        let errorMessage:string = ""; 
        if ((values.password.length < 8) || !/\d/.test(values.password)) {
          errorMessage = "Password must at least 8 chracters + one number";
          setPassvalid(false)
         }
         else {setPassvalid(true)}
         return <Span>{errorMessage}</Span>;
        // GoogleLoginButton  onClick={GoogleLogin}
      }
  const Spinner = () =>{
   
if (loading)
  {return <LoadingSpinner/>;}
    
  }
       
    return (
        <>
        <Container>
        <Styles />
        <GoogleLoginButton  type="submit"  onClick= {GSubmit} disabled={loading? true:false} />
        <FacebookLoginButton  onClick={() => alert("full feature is available for demo purpose, would be there after comply with GDPR")} />
      {Spinner()}
        <FormGroup autoComplete="off" onSubmit={handleSubmit}>
                <Input
                  type="text"
                  name="email"
                  placeholder="Your Email"
                  value={values.email || ""}
                  onChange={handleChange} />
                  <ValidationEmail />
                <InputPassword
                  type="text"
                  name="password"
                  placeholder="Your Password"
                  value={values.password || ""}
                  onChange={handleChange} />  
                  <ValidationPassword />
                  <div>          
                </div>
                <StyledButton name="submit" disabled={ !emailvalid || !passvalid }>{("Submit")}</StyledButton>
            </FormGroup>
            <div>
            <p></p>     
            </div>
            <Large to="/passRecov">{("forget password?")}</Large>
            if you don't have account please register
                <Large to="/signup">{("here")}</Large>
        </Container> </>
     
    )
    //</StyledForm>
    //<StyledForm onSubmit={handleSubmit}>
    //tyledInput type="text" value={username} onChange={e => usernameEntered(e)}
    //invalid={passwordInvalid}
    //StyledInput type="password" value={password} onChange={(e) => passwordEntered(e)
}

export default LoginPage;