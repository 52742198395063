import { Suspense } from "react";
import { Routes, Route, useRoutes } from "react-router-dom";
import Footer from "../components/Footer";
//import Header from "../components/Header";
import routes from "./config";
//import { Styles } from "../styles/styles";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { SuperTokensConfig, ComponentWrapper, PreBuiltUIList } from '../config';
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { canHandleRoute, getRoutingComponent, getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import Home from "../pages/Home/index"
import UserPage from "../pages/UserPage/index"
import PrivecyPolicy from "../pages/PrivecyPolicy/index"
import SignupPage from "../pages/SignupPage";
import LoginPage from "../pages/LoginPage";
import PassRecov from "../pages/PassRecov";
import HGoogle from "../common/Auth/callback/google";


SuperTokens.init(SuperTokensConfig);

const Router = () => {
  
  const routes = getSuperTokensRoutesForReactRouterDom(("react-router-dom"), PreBuiltUIList);

  const elements = useRoutes([
		...routes.map((r) => r.props),
		{
			path: '/',
      element: <Home/>,
		},
    {
			path: '/user',
      element: <ComponentWrapper><SessionAuth><UserPage /></SessionAuth></ComponentWrapper>,
		},
    {
			path: '/auth',
      element: <ComponentWrapper><SessionAuth></SessionAuth></ComponentWrapper>,
		},
    {
			path: '/policy',
      element: <PrivecyPolicy/>,
		},
    {
			path: '/signup',
      element: <SignupPage/>,
		},
    
    {
			path: '/login',
      element: <LoginPage/>,
		},
   {
    path: '/passRecov',
    element: <PassRecov/>
  },
    {
			path: 'Auth/callback/google',
      element: <HGoogle/>,
		},
    
  ])
  return (

    <Suspense fallback={null}>
      <SuperTokensWrapper>
      {elements}
      </SuperTokensWrapper>
      <Footer />
    </Suspense>
 
  );
};

export default Router;
