import { HeaderSection } from "src/components/Header/styles";


export default function PrivacyPolicy(props: PrivacyPolicyProps) {
  return (

    
    <div style={{display: 'flex',  alignItems:'center'}}
      className={`margin-top: 3em flex h-[2972px] w-[1512px] flex-col items-center justify-between gap-[78px] bg-white pb-[180px] text-left ${props.className}`}
    >
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}
        className="font-archivo flex w-[1512px] flex-col items-center justify-between gap-[78px] bg-[#00E492] pb-[47px] font-[600] text-[#22283D]"
      >
      
        <p style={{display: 'flex',  justifyContent:'center', alignItems:'center', color:'white'}}
        
        >
     .........
        </p>
      </div>
      <div  className="h-[2378px] w-[1205px] leading-none">
      <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          Our Policy:
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
        Code recycle is committed to being transparent about how it collects and uses your data and to meeting its data protection obligations. This Privacy Policy is to help you understand what data we collect and what we do with it.
           <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
        Some basic personal informationwil be collected through registeration (e.g. name and email address etc) in order to receive or use services on our website. In addition we automatically collect certain data which we outline below. Some of this information is stored in cookies, you can find out how we use these in our cookies policy.
            <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
  
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          Code recycle processes your (personal information) in this ways:
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
          <ul
            className="font-archivo font-[400] text-[#22283D] [padding-inline-start:1.5em]"
          >
            <li className="list-disc text-2xl">
              <span className="list-disc text-2xl">
              to improve your experience
                <br />
              </span>
            </li>
            <li className="list-disc text-2xl">
              <span className="list-disc text-2xl">
              to allow you to sign in and stay signed in on our site
                <br />
              </span>
            </li>
            <li className="list-disc text-2xl">
              <span className="list-disc text-2xl">
             to measure the effectiveness of our resources and marketing channels
                <br />
              </span>
            </li>
            <li className="list-disc text-2xl">
              <span className="list-disc text-2xl">
              to identify and prevent fraud and spam 
                <br />
              </span>
            </li>
            <li className="list-disc text-2xl">
              <span className="list-disc text-2xl">
              to send you marketing communications, research purposes and to comply with a legal obligation
                <br />
              </span>
            </li>
          </ul>
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          Does Code recycle also process special personal data?
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          Special personal data concerns sensitive data, for example about your health or a criminal record, ethnical data or data concerning race. we do not collect, nor process special personal data.
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
         Sharing your data
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
        Subject to applicable data protection law, we may share your personal data with: Companies who provide services to us, Legal and professional advisors or bodies, Fraud prevention companies, An organisation we sell or license the relevant part of the business in order to retain your legitimate interests and any other organisation where we have gained your consent or where it required by law.
       Whenever we process data for these purposes we will ensure that we always keep your Personal Data rights in high regard and take account of these rights. You have the right to object to this processing if you wish, and if you wish to do so please contact us.
            <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
      
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          How long we keep your data?
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          {
            "We remove accounts that have been inactive for 2 years" }
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
        Where we can’t identify when someone last signed in we will retain the information for the lifetime of the application in order to retain your legitimate interests in using the application.

          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          {
            "User-level and event-level data stored by Google Analytics is automatically deleted from Analytics’ servers after 50 months."   }
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          {
            "You have the option to unsubscribe from one of our email marketing lists, and we will always honour this. We will also periodically clean our lists to ensure they are up-to-date."
     }
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
         At any point while we are in possession of or processing your personal data, you, the data subject, have the following rights,,,
         Right of access – you have the right to request a copy of the information that we hold about you.
         Right of rectification – you have a right to correct data that we hold about you that is inaccurate or incomplete.
         Right to be forgotten – in certain circumstances you can ask for the data we hold about you to be erased from our records.
         Right to restriction of processing – where certain conditions apply to have a right to restrict the processing.
         Right of portability – you have the right to have the data we hold about you transferred to another organisation.
         Right to object – you have the right to object to certain types of processing such as direct marketing.
         Right to object to automated processing, including profiling – you also have the right to be subject to the legal effects of automated processing or profiling.

          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
        In line with the above rights, you have the right to request a copy of the personal information WRAP holds about you, to have any inaccuracies corrected, or request that it be deleted. Please address requests to the person responsible for Data Protection (email: data.protection@code-recycle.com).
             <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
        <p
          className="font-archivo inline text-[32px] font-[700] text-[#22283D]"
        >
          If you are under 18, please get your parent/guardian's permission beforehand whenever you provide personal information to WRAP. Users without this consent are not allowed to provide us with personal information.
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          {
            "Naturally, you always have the possibility to view, correct or delete all personal data known to us. "
          }
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
        If you have any questions about this Privacy Policy or you wish to make a complaint about how your personal data is being processed by us, or how your complaint has been handled, you have the right to lodge a complaint directly with the Supervisory Authority and WRAP's data protection representative.
        </p>
      
    
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          For questions or remarks about the processing of your personal data and this Privacy Statement, you can contact us through 
        </p>
        <p
          className="font-archivo inline text-2xl font-[400] text-[#22283D] underline"
        >
          data.protection@code-recycle.com
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          .
          <br />
        </p>
        <p className="font-archivo inline text-2xl font-[400] text-[#22283D]">
          <br />
        </p>
        <p
          className="font-archivo inline text-base font-[400] tracking-[0.02em] text-[#22283D]"
        >
          This privacy statement was drawn up on 23 Aug 2024
        </p>
      </div>
    </div>
  );
 
}

PrivacyPolicy.defaultProps = {
  className: "",
  style: {},
};

interface PrivacyPolicyProps {
  className: string;
  style: Object;
}

/**
 * This component was generated from Figma with FireJet.
 * Learn more at https://www.firejet.io
 *
 * README:
 * The output code may look slightly different when copied to your codebase. To fix this:
 * 1. Include the necessary fonts. The required fonts are imported from public/index.html
 * 2. Include the global styles. They can be found in App.css
 *
 * Note: Step 2 is not required for tailwind.css output
 */
